import React from 'react'

import { transformProductPrivacyIssue } from '@/utils/productPrivacyIssue'

import ResultsCard from '../ResultsCard'

import {
  COLOR_STATUS,
  Props,
} from './types'

const getColor = (isClear: boolean, status: COLOR_STATUS) => {
  if (!isClear) {
    return 'orange'
  }

  switch (status) {
    case 'good':
      return 'green'
    case 'bad':
      return 'blue'
    default:
      return 'blue'
  }
}

// disabling this for now as we might revert back to disclose practices when score is 0
const getPracticesButtonText = ({ hasAnswers }: {hasAnswers: boolean}) => {
  if (hasAnswers) {
    return 'Update Practices'
  }

  return 'Disclose Practices'
}

const getUrl = (maxScore: number, productPrivacyIssueId: string) => {
  if (maxScore > 0) {
    return `/dashboard/privacy-issues/${productPrivacyIssueId}`
  }

  return undefined
}

function PrivacyIssueCard({
  data,
  isList = true,
  hasUrl = true,
  onPracticesClick,
  hasPracticesButton,
}: Props): React.ReactElement {
  const {
    id,
    code,
    answerStatement,
    isClear,
    importanceLevel,
    score,
    maxScore,
    question,
    status,
    tags,
    answers,
  } = transformProductPrivacyIssue(data)

  const color = getColor(isClear, status)
  const practicesButtonText = hasPracticesButton ? getPracticesButtonText({ hasAnswers: !!answerStatement }) : ''
  const url = hasUrl ? getUrl(maxScore, id) : undefined
  const numRecommendedActions = (data?.answers && data?.answers?.length > 0 && score < maxScore && data?.privacyIssue?.privacyIssueRecommendedActions?.length) || 0

  let transformedIssueDesc = isClear ? answerStatement : question
  if (code === '2.1.2') {
    const areAllAnswersNo = answers.every(answer => answer.answer?.internalValue === 'No')
    if (areAllAnswersNo) {
      transformedIssueDesc = 'The product does not collect any categories of personally identifiable information.'
    }
    else {
      const collectedPIIs = answers
        .filter(answer => answer.answer?.internalValue === 'Yes')
        .map(answer => answer.answer?.value).join(', ')
      transformedIssueDesc = `The product collects the following categories of personally identifiable information: ${collectedPIIs}.`
    }
  }

  return (
    <ResultsCard
      issueNum={code}
      color={color}
      issueDesc={transformedIssueDesc}
      categories={tags}
      score={score}
      answer={isClear ? 'Clear' : 'Unclear'}
      importance={importanceLevel}
      isList={isList}
      url={url}
      numRecommendedActions={numRecommendedActions}
      practicesButtonText={practicesButtonText}
      onPracticesClick={onPracticesClick}
    />
  )
}

export default React.memo(PrivacyIssueCard)
