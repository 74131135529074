import validation from '../../../validation'
import rules from '../../../validation/rules'

const postBodyRules = {
  email: rules.string().email('Enter a valid email address.').required().label('Email'),
  password: rules.string().required().label('Password'),
}

const postBodyRulesByToken = { token: rules.string().required().label('Impersonate Token') }

const postBody = {
  rules: postBodyRules,
  schema: validation.schema(postBodyRules),
}

const postBodyByToken = {
  rules: postBodyRulesByToken,
  schema: validation.schema(postBodyRulesByToken),
}

// eslint-disable-next-line import/prefer-default-export
export const post = { body: postBody }

export const postByToken = { body: postBodyByToken }
