import React, {
  useCallback,
  useMemo,
} from 'react'
import { useDispatch } from 'react-redux'

import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Link,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'

import { api } from '@common-sense-privacy/common'

import Form from '@/components/Form'
import FormField from '@/components/FormField'
import { useAlert } from '@/context/AlertContext'
import { usePatchOrganizationMutation } from '@/services/organization'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'
import useNavigate from '@/hooks/useNavigate'
import { useCurrentOrganization } from '@/hooks/useSession'
import { setUserOrganization } from '@/state/slices/session'

import StateField from '@/components/Account/OrganizationState'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
} from './types'

const { COUNTRY_LIST: countryList } = api.endpoints.organization.validation

function EditOrganization(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const { setAlert } = useAlert()

  const {
    id: organizationId,
    name,
    size,
    email,
    city,
    state,
    zipCode,
    poBox,
    streetAddress,
    country,
    hasProductPolicies,
  } = useCurrentOrganization()

  const [
    patchOrganization,
    { isLoading },
  ] = usePatchOrganizationMutation()

  const initialValues = useMemo(() => ({
    name,
    size,
    email,
    city,
    state,
    zipCode,
    poBox,
    streetAddress,
    organizationCountry: country,
    hasProductPolicies,
  }), [
    city,
    country,
    email,
    name,
    poBox,
    size,
    state,
    streetAddress,
    zipCode,
    hasProductPolicies,
  ])

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    patchOrganization({
      body: { ...values },
      params: { id: organizationId },
    })
      .unwrap()
      .then(response => {
        navigate('/account')
        if (response.data) {
          dispatch(setUserOrganization(response.data))
        }
        setAlert({
          description: 'Success! Your changes have been saved.',
          type: 'success',
        })
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    navigate,
    organizationId,
    dispatch,
    handleFormApiErrors,
    setAlert,
    patchOrganization,
  ])

  const { rules } = api.endpoints.organization.validation.patch.body
  const formRules = { ...rules }
  const organizationSizes = api.endpoints.signup.validation.ORGANIZATION_SIZES

  return (
    <DocumentWrapper title='Common Sense Privacy | My Account Organization Details'>
      <ScreenWrapper>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/account' variant='body2'>
              Account
            </Link>
            <Typography color='text.primary' variant='body2'>Edit Organization Details</Typography>
          </Breadcrumbs>
          <Typography variant='h1'>Organization details</Typography>
          <Typography variant='intro' mb={4}>Edit your organization information</Typography>
          <Divider />
        </Container>
        <Container>
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={formRules}>
            <Stack maxWidth='sm' spacing={4} mt={6}>
              <FormField
                name='name'
                id='organization'
                label='Organization Name *'
                variant='outlined'
                inputProps={{ 'data-testid': 'organization-name' }}
              />
              <FormField
                type='select'
                name='size'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Organization Size *'
                inputProps={{ 'data-testid': 'organization-size' }}
              >
                {organizationSizes.map((size: string) => (
                  <MenuItem key={size} value={size}>{size}</MenuItem>
                ))}
              </FormField>
              <FormField
                name='email'
                label='Organization Email *'
                variant='outlined'
                inputProps={{ 'data-testid': 'account-edit-organization-email' }}
                fieldHelperText='Organization email will appear in the privacy policy.'
              />
              <FormField
                type='select'
                name='organizationCountry'
                labelId='orgnization-country-select-label'
                id='organization-country-select'
                label='Country/State of Organziation *'
                inputProps={{ 'data-testid': 'account-edit-organization-country' }}
                data-testid='account-edit-organization-country'
              >
                {countryList.map(item => (
                  <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
                ))}
              </FormField>
              <FormField
                name='streetAddress'
                id='streetAddress'
                label='Street Address *'
                variant='outlined'
                type='text'
                inputProps={{ 'data-testid': 'account-edit-streetAddress' }}
              />
              <FormField
                name='city'
                label='City *'
                variant='outlined'
                type='text'
                inputProps={{ 'data-testid': 'account-edit-city' }}
              />
              <Stack direction='row' spacing={2}>
                <StateField />
                <FormField
                  name='zipCode'
                  label='Zip Code *'
                  variant='outlined'
                  type='text'
                  inputProps={{ 'data-testid': 'account-edit-zip-code' }}
                />
              </Stack>
              <FormField
                name='poBox'
                label='PO Box'
                variant='outlined'
                type='text'
                inputProps={{ 'data-testid': 'account-edit-po-box' }}
              />
              <FormField
                type='select'
                name='hasProductPolicies'
                labelId='orgnization-has-product-policies-select-label'
                id='organization-has-product-policies-select'
                label='Do you have an existing privacy policy? *'
                inputProps={{ 'data-testid': 'account-edit-organization-has-product-policies' }}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </FormField>
              <Stack direction='row' spacing={2}>
                <Button variant='contained' type='submit' disabled={isLoading}>Save</Button>
                <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
              </Stack>
            </Stack>
          </Form>
          <Stack mt={8}>
            <Divider />
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(EditOrganization)
