import {
  Box,
  Container,
  FormControl,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

import { sanitize } from 'dompurify'

import dateTime from '@/utils/dateTime'

import { ResourceProps } from './types'

function Resource({ article }: ResourceProps): React.ReactElement {
  return (
    <Container>
      <Stack mb={8} maxWidth='750px' mx='auto'>
        {article.photoUrl && (
          <Box
            component='img'
            sx={{
              height: 'auto',
              width: '100%',
              maxWidth: 650,
              margin: '0 auto 40px auto',
            }}
            alt='Featured Image'
            src={article.photoUrl}
          />
        )}
        {/* 09.07.2022 */}
        <Typography variant='body3' color='text.secondary' mb={1}>{dateTime.format(article.publishedAt || new Date(), 'MM.dd.yyyy')} - Common Sense Media</Typography>
        <Typography variant='h1' mb={2}>{article.title}</Typography>
        {/* this is just a temporary fix as we need to update the theme and make a custom variant */}
        {/* eslint-disable-next-line react/no-danger */}
        <FormControl variant='outlined' dangerouslySetInnerHTML={{ __html: sanitize(article.content || '', { ADD_ATTR: [ 'target' ] }) }} />
        {article.source
        && (
          <Box>
            <Typography variant='body1' fontWeight='bold' mb={2}>Source: </Typography>
            <Typography> <Link href={article.source}>{article.source}</Link></Typography>
          </Box>
        )}
        {/* not present in this version */}
        {/* <Box mt={4}>
                <Chip
                  sx={{
                    marginBottom: '6px',
                    marginRight: '6px',
                  }}
                  key='1.0.0'
                  label='1.0.0'
                  variant='results'
                />
                <Chip
                  sx={{
                    marginBottom: '6px',
                    marginRight: '6px',
                  }}
                  key='Data Sharing'
                  label='Data Sharing'
                  variant='results'
                />
                <Chip
                  sx={{
                    marginBottom: '6px',
                    marginRight: '6px',
                  }}
                  key='Transparency'
                  label='Transparency'
                  variant='results'
                />
              </Box> */}
      </Stack>
    </Container>
    // {/* Not present in this version */}
    // {/* <Container>
    //     <Stack spacing={2} mb={3}>
    //       <Typography variant='h4'>Related Resources & Guides</Typography>
    //       <Divider />
    //     </Stack>
    //     <Grid container={true} spacing={8} mb={6}>
    //       <Grid item={true} xs={12} sm={6} md={4}>
    //         <ResourceCard
    //           date='09.07.2022 - Common Sense Media'
    //           title='Best Practices for Material Updates to Your Privacy Policy'
    //           description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
    //           link='/dashboard/resources/detail'
    //           categories={[
    //             '1.1.2',
    //             'Data Sharing',
    //           ]}
    //         />
    //       </Grid>
    //       <Grid item={true} xs={12} sm={6} md={4}>
    //         <ResourceCard
    //           date='09.07.2022 - Common Sense Media'
    //           title='9 simple ways to update customers on policy changes'
    //           description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
    //           link='/dashboard/resources/detail'
    //           categories={[
    //             'CalOPPA',
    //             '1.1.2',
    //             'Data Sharing',
    //           ]}
    //         />
    //       </Grid>
    //       <Grid item={true} xs={12} sm={6} md={4}>
    //         <ResourceCard
    //           date='09.07.2022 - Common Sense Media'
    //           title='Best Practices for Material Updates to Your Privacy Policy'
    //           description='When sending an update notice it is good practice to include.'
    //           link='/dashboard/resources/detail'
    //           categories={[
    //             'CalOPPA',
    //             '1.1.2',
    //             'Data Sharing',
    //           ]}
    //         />
    //       </Grid>
    //     </Grid>
    //   </Container> */}
  )
}

export default React.memo(Resource)
