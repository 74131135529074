import validation from '../../../validation'
import rules from '../../../validation/rules'

// Organization Size and Tiers
// Tier 1: 1-5
// Tier 1: 6-15
// Tier 1: 16-25
// Tier 1: 26-50
// Tier 1: 51-100
// Tier 2: 101-150
// Tier 2: 151-200
// Tier 2: 201-250
// Tier 2: 251-500
// Tier 2: 501-1000
// Tier 3: 1001+
export const tier1 = [ '0-10' ]

export const tier2 = [ '11-49' ]

export const tier3 = [ '50+' ]

export const ORGANIZATION_SIZES = [
  ...tier1,
  ...tier2,
  ...tier3,
]

const postBodyRules = {
  firstName: rules.string().required().label('First Name'),
  lastName: rules.string().required().label('Last Name'),
  jobTitle: rules.string().optional().label('Job Title'),
  roles: rules.array().of(rules.string().required()).optional()
    .label('Functional Roles'),
  organizationName: rules.string().required().label('Organization'),
  organizationSize: rules.string().oneOf(ORGANIZATION_SIZES).required().label('Organization Size'),
  email: rules.string().email().required().label('Email'),
  password: rules.string().password().required().label('Password'),
  confirmPassword: rules.string().oneOf([
    rules.ref('password'),
    undefined,
  ], 'Passwords must match').label('Confirm Password'),
  organizationEmail: rules.string().email({ isOrganizationEmail: true }).required().label('Organization Email'),
  streetAddress: rules.string().required().label('Street Address'),
  city: rules.string().required().label('City'),
  zipCode: rules.string().required().label('Zip Code'),
  state: rules.string().when('organizationCountry', {
    is: (val: unknown) => String(val).toLowerCase() === 'us',
    then: schema => schema.required(),
    otherwise: schema => schema.optional(),
  }).label('State'),
  poBox: rules.string().optional().label('PO Box'),
  organizationCountry: rules.string().required().label('Country/State of Organization'),
  hasProductPolicies: rules.number().oneOf([
    0,
    1,
  ]).required().label('Do you have an existing privacy policy?'),
  agreeToTerms: rules.boolean()
    .oneOf([ true ], 'Please agree to the Terms & Privacy Policy before proceeding')
    .label('Terms & Privacy Policy'),
}

const postBody = {
  rules: postBodyRules,
  schema: validation.schema(postBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const post = { body: postBody }
