import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Link,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material'
import React, {
  useCallback,
  useState,
  useEffect,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  api,
  validation,
} from '@common-sense-privacy/common'

import Form from '@/components/Form'
import FormField from '@/components/FormField'
import { useAlert } from '@/context/AlertContext'

import { useGetProductTypesQuery } from '@/services/productTypes'
import { useGetCategoriesQuery } from '@/services/categories'
import { usePatchProductMutation } from '@/services/organization/product'
import { useGetDistributionMethodsQuery } from '@/services/distributionMethods'

import {
  useCurrentOrganization,
  useCurrentProduct,
  setProduct,
} from '@/hooks/useSession'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
} from './types'

function EditProduct(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id: organizationId } = useCurrentOrganization()
  const {
    id: currentProductId,
    name: currentProductName,
    type: currentProductType,
    categories: currentProductCategories,
    distributionMethods: currentDistributionMethods,
    privacyPolicyIntroduction: currentPrivacyPolicyIntroduction,
  } = useCurrentProduct()
  const { setAlert } = useAlert()

  const {
    data: productTypes,
    isLoading: isProductTypesLoading,
  } = useGetProductTypesQuery(undefined, { refetchOnMountOrArgChange: true })

  const {
    data: categories,
    isLoading: isCategoriesLoading,
  } = useGetCategoriesQuery(undefined, { refetchOnMountOrArgChange: true })

  const {
    data: distributionMethods,
    isLoading: isDistributionMethodsLoading,
  } = useGetDistributionMethodsQuery(undefined, { refetchOnMountOrArgChange: true })

  const [
    patchProduct,
    { isLoading: isUpdatingProduct },
  ] = usePatchProductMutation({ fixedCacheKey: 'shared-creating-product' })
  const handleFormApiErrors = useHandleFormApiErrors()
  const [
    selectedProductType,
    setSelectedProductType,
  ] = useState(currentProductType.name)

  useEffect(() => {
    if (productTypes && !selectedProductType && currentProductType.id) {
      setSelectedProductType(productTypes.data
        ?.find(productType => productType.id === currentProductType.id)?.name || '')
    }
  }, [
    productTypes,
    selectedProductType,
    setSelectedProductType,
    currentProductType.id,
  ])

  const initialValues = {
    name: currentProductName,
    type: currentProductType.name || '',
    categories: currentProductCategories.map(category => category.id),
    distributionMethods: currentDistributionMethods?.map(distMethod => distMethod.id) || [],
    privacyPolicyIntroduction: currentPrivacyPolicyIntroduction,
  }

  const formRules = {
    name: validation.rules.string().required().label('Product Name'),
    ...api.endpoints.organization.product.validation.patch.body.rules,
    privacyPolicyIntroduction: validation.rules.string().nullable().label('Privacy Policy Introduction'),
  }

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    patchProduct({
      body: {
        type: productTypes?.data?.find(type => type.name === values.type)?.id || '',
        categories: values.categories,
        distributionMethods: values.type === 'Mobile App' ? values.distributionMethods : undefined,
        privacyPolicyIntroduction: values.privacyPolicyIntroduction || '',
      },
      params: {
        id: organizationId,
        productId: currentProductId,
      },
    }).unwrap().then(response => {
      if (response.data) {
        dispatch(setProduct({
          organizationId,
          product: response.data,
        }))
      }
      navigate('/account')
      setAlert({
        description: 'Success! Your changes have been saved.',
        type: 'success',
      })
    }).catch(error => {
      handleFormApiErrors({
        error,
        setErrors,
        showFieldErrorsAsToast: true,
      })
    })
  }, [
    patchProduct,
    productTypes?.data,
    organizationId,
    currentProductId,
    navigate,
    setAlert,
    dispatch,
    handleFormApiErrors,
  ])

  return (
    <DocumentWrapper title='Common Sense Privacy | Edit Product Information'>
      <ScreenWrapper>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/account' variant='body2'>
              Account
            </Link>
            <Typography color='text.primary' variant='body2'>Edit Product Details</Typography>
          </Breadcrumbs>
          <Typography variant='h1'>Product details</Typography>
          <Typography variant='intro' mb={4}>Edit your product information</Typography>
          <Divider />
        </Container>
        <Container>
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={formRules} marginBottom={6} spacing={4} mt={6} alignItems='flex-start' maxWidth='sm'>
            <FormField
              name='name'
              label='Product Name'
              formControlProps={{ fullWidth: true }}
              InputProps={{ disabled: true }}
            />
            <FormField
              name='type'
              type='select'
              labelId='product-type-select-label'
              id='product-type-select'
              label='Product Type'
              formControlProps={{ fullWidth: true }}
              data-testid='product-type-wrapper'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedProductType(e.target.value)}
            >
              {(productTypes?.data || []).map(productType => (
                <MenuItem
                  key={productType.id}
                  value={productType.name}
                >
                  {productType.name}
                </MenuItem>
              ))}
            </FormField>
            {selectedProductType === 'Mobile App' && (
              <FormField
                name='distributionMethods'
                type='select'
                multiple={true}
                label='Product Distribution Methods'
                labelId='product-distribution-methods-select-label'
                formControlProps={{ fullWidth: true }}
                data-testid='product-distribution-methods-wrapper'
              >
                {(distributionMethods?.data || []).map(method => (
                  <MenuItem key={method.id} value={method.id}>
                    {method.name}
                  </MenuItem>
                ))}
              </FormField>
            )}
            <FormField
              name='categories'
              type='select'
              multiple={true}
              label='Product Categories'
              labelId='product-industry-select-label'
              formControlProps={{ fullWidth: true }}
              data-testid='product-categories-wrapper'
            >
              {(categories?.data || []).map(category => (
                <MenuItem
                  key={category.id}
                  value={category.id}
                >
                  {category.name}
                </MenuItem>
              ))}
            </FormField>
            <FormField
              name='privacyPolicyIntroduction'
              type='text'
              multiline={true}
              maxRows={Infinity}
              minRows={4}
              label='Privacy Policy Introduction (Optional)'
              infoTip='If you wish to add a custom introduction to your privacy policy, enter it here.'
              formControlProps={{
                fullWidth: true,
                sx: { mt: 0 },
              }}
            />
            <Stack direction='row' spacing={2}>
              <Button
                variant='contained'
                disabled={
                  isProductTypesLoading || isCategoriesLoading
                  || isUpdatingProduct || isDistributionMethodsLoading
                }
                role='button'
                type='submit'
              >Save
              </Button>
              <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
            </Stack>
          </Form>
          <Stack my={4}>
            <Divider />
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(EditProduct)
