import { useFormikContext } from 'formik'
import React from 'react'

import { api } from '@common-sense-privacy/common'

import { MenuItem } from '@mui/material'

import { FormValues } from '@/screens/Account/EditOrganization/types'
import FormField from '@/components/FormField'

const { US_STATES_LIST: usStateList } = api.endpoints.organization.validation

export default function StateField() {
  const {
    values: {
      organizationCountry, state,
    }, setFieldValue,
  } = useFormikContext<FormValues>()

  const isUS = String(organizationCountry).toLowerCase() === 'us'

  React.useEffect(() => {
    if (setFieldValue && !isUS) {
      setFieldValue('state', '')
    }
  }, [
    isUS,
    setFieldValue,
  ])

  return (
    <FormField
      name='state'
      label='State *'
      labelId='orgnization-state-select-label'
      variant='outlined'
      type='select'
      value={state || ''}
      disabled={!isUS}
      inputProps={{ 'data-testid': 'signup-state' }}
      sx={{ minWidth: 180 }}
      onChange={(e: {target: {value: string}}) => setFieldValue && setFieldValue('state', e.target.value)}
    >
      {
        usStateList.map(item => (
          <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
        ))
      }
    </FormField>
  )
}
