import React, { useMemo } from 'react'
import {
  Alert as MuiAlert,
  Snackbar,
  Link,
} from '@mui/material'

import { Link as RouterLink } from 'react-router-dom'

import { parseTextWithLinks } from '@/utils/parse'

import { AlertProps } from './types'

function Alert({
  openError,
  onClose,
  description,
  type = 'error',
}: AlertProps): React.ReactElement {
  const descriptionTokens = useMemo(
    () => description ? parseTextWithLinks(description) : [],
    [ description ],
  )

  return (
    <Snackbar
      open={openError}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{ zIndex: 10000 }}
    >
      <MuiAlert onClose={onClose} severity={type} sx={{ width: '100%' }} variant='filled'>
        {descriptionTokens.map(token => token.type === 'text' ? token.value : (
          <Link key={token.text} component={RouterLink} to={token.url || '/'} color='inherit'>
            {token.text}
          </Link>
        ))}
      </MuiAlert>
    </Snackbar>
  )
}

export default Alert
